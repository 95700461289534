<script>
  export let props = null;
  export let blockClass = "";
  export let gridColumn = "g-col-12";
</script>

<div
  class={"block" + (blockClass ? " " + blockClass : "")}
  id={props?.tocData?.tag}
>
  <div class="container">
    <div class="grid">
      <div class={gridColumn}>
        <slot />
      </div>
    </div>
  </div>
</div>
