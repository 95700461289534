<script lang="ts">
  import { previewActiveModal } from "$lib/services/store";
  import { fade, fly } from "svelte/transition";
  import Button from "$lib/main/components/Button.svelte";
  import { onDestroy } from "svelte";

  export let toggleModal = false;
  export let removeHeader = false;
  export let addClass: string = "";
  export let fullWidth: boolean = false;

  let offsetTop: number;

  const disableBodyScroll = () => {
    if (typeof window !== "undefined") {
      document.body.classList.add("no-scroll");
    }
  };

  const enableBodyScroll = () => {
    if (typeof window !== "undefined") {
      document.body.classList.remove("no-scroll");
    }
  };

  $: if (toggleModal) {
    disableBodyScroll();
  } else {
    enableBodyScroll();
  }

  onDestroy(() => {
    enableBodyScroll();
  });
</script>

<svelte:window bind:scrollY={offsetTop} />

{#if toggleModal}
  <div class={`modal`}>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div
      class="overlay"
      in:fade={{ duration: 250, delay: 50 }}
      out:fade={{ duration: 250 }}
      on:click={() => {
        toggleModal = !toggleModal;
        $previewActiveModal = false;
      }}
    ></div>

    <div
      class={"modal-box" + (addClass ? " " + addClass : "")}
      in:fly={{ y: -5, duration: 250, delay: 50 }}
      out:fly={{ y: 5, duration: 250 }}
      style={`top: calc(${offsetTop}px + 3rem); ${fullWidth ? "left:0;" : ""} ${fullWidth ? "max-width:100%;" : ""} ${fullWidth ? "width:100%;" : ""}`}
    >
      {#if !removeHeader}
        <header class="flex-sb-c">
          <h4><slot name="header">Modal header</slot></h4>
          <Button
            addClass="secondary small"
            on:click={() => {
              toggleModal = !toggleModal;
              $previewActiveModal = false;
            }}>Close</Button
          >
        </header>
      {/if}
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
{/if}

<style>
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
</style>
