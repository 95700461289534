<script>
  import Icons from "$lib/main/components/Icons.svelte";

  export let addClass = "";
  export let link = undefined;
  export let icon = undefined;
  export let onlyIcon = false;
  export let disabled = false;
  export let iconRight = false;
  export let iconLeft = false;
  export let type = "button";
  export let title = null;
  export let iconStroke = "2";
  export let iconSize = "16";
  export let target = null;
  export let variant = '';
</script>

{#if link}
  <a href={link} class={ "button-link" + (addClass ? " " + addClass : "")} class:icon {title} {target}>
    {#if icon && iconLeft && !iconRight}<Icons color={link ? '#2980b9' : '' } name={icon} strokeWidth={iconStroke} width={iconSize + "px"} height={iconSize + "px"} />{/if}
    <span><slot /></span>
    {#if icon && iconRight && !iconLeft}<Icons color={link ? '#2980b9' : '' } name={icon} strokeWidth={iconStroke} width={iconSize + "px"} height={iconSize + "px"} />{/if}
  </a>
{:else}
  <button class={`${variant} ${addClass}`} {type} {disabled} {title} class:icon class:onlyIcon on:click>
    {#if onlyIcon}
      {#if icon}<Icons name={icon} strokeWidth={iconStroke} width={iconSize + "px"} height={iconSize + "px"} />{/if}
    {:else if addClass.includes("lucide-icon")}
      <!-- TODO: temp for switching over to Lucide icons -->
      <slot />
    {:else}
      <span><slot /></span>
      {#if icon}<Icons name={icon} strokeWidth={iconStroke} width={iconSize + "px"} height={iconSize + "px"} />{/if}
    {/if}
  </button>
{/if}

<style>
  .button-link{
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .text {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    font-size: 16px;
  }
</style>